import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/hu/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/hu/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Mi történt a győzelem után | A varsói csata " description="Lwów védelme, harc Zadwórzeért, a Nyeman menti csata – a varsói győzelem még nem zárta le a háborút." lang="hu" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Mi történt a győzelem után | A varsói csata " description="Lwów védelme, harc Zadwórzeért, a Nyeman menti csata – a varsói győzelem még nem zárta le a háborút." lang="hu" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
